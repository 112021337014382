@import url('https://fonts.googleapis.com/css?family=Rubik:400,600,700');
@import 'node_modules/bootstrap/scss/bootstrap';

body {
  font-family: 'Rubik', sans-serif;
}

.margin {
  margin-top: 20px;
}

.features {
  margin-top: 40px;
}

pre {
  background-color: #ecf0f1;
  padding: 15px;
  border: 1px solid #cccccc;
  display: block;
  font-size: 14px;
  border-radius: 4px;
  word-wrap: break-word;
  word-break: break-all;
}

.feature-box .feature-img-icon {
  width: 100px;
  height: 100px;
  text-align: center;
  background: #fff;
  padding-top: 20px;
  margin: 0 auto;
  border-radius: 100%;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}
.feature-box {
  margin-bottom: 40px;
  background: #fff;
  text-align: center;
  padding: 25px 25px 30px 25px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 5px;
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  text-align: center;
}

.footer {
  margin-bottom: 25px;
}

.upload-lifetime {
  padding-top: 20px;
}
/*
.bg-features {
  background-color: #635cdb;
}
*/

li {
  list-style: none;
}

.copy-secret-button {
  border-radius: 3px 3px 0px 0px;
  display: block;
  margin-right: auto;
  margin-left: 0;

  margin-bottom: -2px;
  padding: 3px 8px;
  font-size: 0.8em;
}
